<template>
  <div v-if="isGrid" class="grid-view" aria-live="polite">
    <template v-for="(item, n) in data" :key="n">
      <ImageReview :content-type="item.Logo.contentType" :nid="item.Logo.nid" />
    </template>
  </div>
  <div v-else class="grid-view" aria-live="polite">
    <v-card v-for="(item, n) in getObjectData(data, contentType, isJackpots)" :key="n" class="text-center">
      <slot name="card-title-image" v-bind="item">
        <slot name="card-title">
          <v-card-title v-if="item.Title" class="text-center">
            <smart-link v-if="item.uri" :to="item.uri">{{ item.Title }}</smart-link>
            <template v-else>{{ item.Title }}</template>
          </v-card-title>
        </slot>
        <slot v-if="item.Logo || (isJackpots && item.nid)" name="card-img">
          <ImageReview
            :content-type="item?.Logo?.contentType"
            :nid="item?.Logo?.nid || item?.nid"
            class="ma-4"
            :alt="item?.Logo?.alt || item?.Title"
            :download-nick="item?.Logo?.downloadNick"
            :software-title="item?.Logo?.software"
          />
        </slot>
        <Rating v-if="item?.Popularity.value" v-model="item.Popularity.value" class="pb-3"></Rating>
      </slot>
      <slot
        v-if="item?.taxonomy && typeof item?.taxonomy === 'object' && Object.keys(item?.taxonomy).length > 0"
        name="card-text"
      >
        <v-card-text class="taxonomy-data">
          <Taxonomy :taxonomy="item.taxonomy" />
        </v-card-text>
      </slot>
      <slot v-if="item['Download Button']" name="card-actions">
        <v-card-actions>
          <Button
            :download-nick="item['Download Button'].downloadNick"
            :software-title="item['Download Button'].software"
            class="cardViewButton"
            :text="page.downloadTitle"
          />
        </v-card-actions>
      </slot>
    </v-card>
  </div>
</template>

<script setup>
import { getObjectData } from "@/lib/tools.js";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  isGrid: {
    type: Boolean,
    default: false,
  },
  isJackpots: {
    type: Boolean,
    default: false,
  },
});

const page = inject("page");
const contentType = unref(page).typename;
</script>
